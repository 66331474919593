import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import Layout from "../components/Layout";
import { HowItWorks } from "../components/HowItWorks";
import { EnterEmail, EmailCta } from "../components/EnterEmail";
import { Helmet } from "react-helmet";

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    }
  }
};

export default function Home ({ data }) {
  console.log(`data`, data);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Itadise</title>
      </Helmet>
      <div className="bg-white">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">Coming soon</span>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                  <span className="block text-gray-900">{data.contentfulLandingPage.headline}</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {data.contentfulLandingPage.subHeadline.subHeadline}
              </p>
              <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                <EmailCta />
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <svg className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784" aria-hidden="true">
                <defs>
                  <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
                <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
              </svg>
              <div className="relative mx-auto w-full lg:max-w-md">
                <img className="w-full" src={data.contentfulLandingPage.headlineImage.file.url} alt={data.contentfulLandingPage.headlineImage.title} />
              </div>
            </div>
          </div>

          {/* This example requires Tailwind CSS v2.0+ */}
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold text-gray-900">{data.contentfulLandingPage.featureHeadline}</h2>
                <p className="mt-4 text-lg text-gray-500">{data.contentfulLandingPage.featureSubheadline.featureSubheadline}</p>
              </div>
              <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                {data.contentfulLandingPage.features.map(ft => (
                  <div key={ft} className="flex">
                    <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <div className="ml-3">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        {ft}
                      </dt>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Alternating Feature Sections */}
          <div className="relative pt-16 pb-32 overflow-hidden">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48"></div>
            <div className="relative">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                  <div>
                    <div className="mt-6">
                      <h4 className="text-xl font-extrabold tracking-tight text-green-300">
                        {data.contentfulLandingPage.firstParagraphSubheadline}
                      </h4>
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {data.contentfulLandingPage.firstParagraphHeadline}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        {data.contentfulLandingPage.firstParagraphText.firstParagraphText}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src={data.contentfulLandingPage.firstParagraphImage.file.url} alt={data.contentfulLandingPage.firstParagraphImage.title} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-24">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                  <div>
                    <div className="mt-6">
                      <h4 className="text-xl font-extrabold tracking-tight text-green-300">
                        {data.contentfulLandingPage.secondParagraphSubheadline}
                      </h4>
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {data.contentfulLandingPage.secondParagraphHeadline}
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        {data.contentfulLandingPage.secondParagraphText.secondParagraphText}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                  <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                    <img className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src={data.contentfulLandingPage.secondParagraphImage.file.url} alt={data.contentfulLandingPage.secondParagraphImage.title} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HowItWorks />
          <EnterEmail />
        </main>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulLandingPage {
      headline
      subHeadline {
        subHeadline
      }
      features
      featureHeadline
      featureSubheadline {
        featureSubheadline
      }
      headlineImage {
        file {
          url
        }
        title
      }
      firstParagraphHeadline
      firstParagraphImage {
        title
        file {
          url
        }
      }
      firstParagraphSubheadline
      firstParagraphText {
        firstParagraphText
      }
      secondParagraphHeadline
      secondParagraphImage {
        title
        file {
          url
        }
      }
      secondParagraphSubheadline
      secondParagraphText {
        secondParagraphText
      }
    }
  }
`
